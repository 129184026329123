<template>
  <!-- Delete Package Modal -->
  <confirm-modal
    modalDialogClasses="modal-notice"
    v-bind:title="$t('packages.delete.modal.title')"
    v-bind:body="$t('packages.delete.modal.body')"
    confirmBtnClass="btn-danger"
    declinedBtnClass="btn-default"
    @confirmed="deletePackage()"
    :confirmBtn="$t('global.delete')"
  >
    <button
      ref="deleteButton"
      class="btn btn-clean d-none remove_btn font-size-default"
    >
      <i class="far fa-trash-alt font-size-md me-1"></i>
      {{ $t("cart.clear_cart") }}
    </button>
  </confirm-modal>
  <!-- Update Name -->
  <confirm-modal
    modalDialogClasses="modal-notice"
    :title="$t('packages.edit.modal.name.title')"
    declinedBtnClass="btn-default"
    :closeButton="true"
    :showButtons="false"
  >
    <template #body>
      <div class="body-holder font-size-md mt-3">
        {{ $t("packages.edit.modal.name.body") }}
      </div>
      <Form v-on:submit="updateName" class="new-package-add">
        <div class="form-container">
          <div class="inputContainer">
            <label
              class="labelStyle"
              :class="{ inFocusInput: isFocused }"
              :for="id"
            >
              {{ $t("packages.edit.modal.name.label") }}
            </label>

            <Field
              rules="required"
              :id="id"
              class="inputStyle font-size-md"
              @focus="onFocus"
              type="text"
              v-model="modelValue"
              @blur="onBlur"
              name="name"
              :placeholder="placeholder"
            />
          </div>
          <validation-error name="name" class="errorMessage" />
          <ErrorMessage name="name" class="errorMessage" />
          <button type="submit" class="btn btn-primary p-2 mt-3">
            {{ $t("packages.edit.modal.name.button") }}
          </button>
        </div>
      </Form>
      <button
        ref="closeOnSubmit"
        data-bs-dismiss="modal"
        type="text"
        class="btn btn-default w-100"
      >
        {{ $t("global.cancel") }}
      </button>
    </template>
    <button ref="updateName" class="d-none"></button>
  </confirm-modal>

  <!-- Recurrings Modal -->
  <confirm-modal
    modalDialogClasses="modal-notice"
    :title="$t('packages.update.modal.title')"
    declinedBtnClass="btn-default"
    :closeButton="true"
    :showButtons="false"
  >
    <template #body>
      <div class="body-holder font-size-md mt-3">
        {{ $t("packages.update.modal.body") }}
      </div>
      <Form v-on:submit="updatePackageRecurrings" class="new-package-add">
        <div class="form-container">
          <div class="inputContainer customise">
            <div class="input-holder">
              <form-check-input
                class="customise color-secondary"
                type="radio"
                name="recurring_type"
                :checked="recurringType == 'onetime' || recurringType == null"
                value="onetime"
                @changed="recurringTypeChanged('onetime')"
              >
                {{ $t("packages.update.modal.fields.specific") }}
              </form-check-input>

              <div v-if="recurringType == 'onetime'" class="datetime holder">
                <Field
                  class="inputStyle date font-size-md"
                  name="date"
                  type="date"
                  :min="minDate"
                  rules="required"
                  v-model="oneTime.date"
                />

                <validation-error name="date" class="errorMessage" />
                <ErrorMessage name="date" class="errorMessage" />
                <Field
                  class="inputStyle time font-size-md"
                  name="time"
                  type="time"
                  rules="required"
                  v-model="oneTime.time"
                />

                <validation-error name="time" class="errorMessage" />
                <ErrorMessage name="time" class="errorMessage" />
              </div>
            </div>

            <div class="input-holder">
              <form-check-input
                class="customise color-secondary"
                type="radio"
                name="recurring_type"
                value="weekly"
                :checked="recurringType == 'weekly'"
                @changed="recurringTypeChanged('weekly')"
              >
                {{ $t("packages.update.modal.fields.weekly") }}
              </form-check-input>
              <div v-if="recurringType == 'weekly'" class="datetime holder">
                <Field
                  class="inputStyle time font-size-md"
                  name="time"
                  type="time"
                  rules="required"
                  v-model="weekly.time"
                />

                <validation-error name="time" class="errorMessage" />
                <ErrorMessage name="time" class="errorMessage" />
                <DayDropDown
                  :recurring_type="recurringType"
                  @showHide="showHide"
                  :hidden="hidden"
                  :Days="WEEK"
                  :theDay="weekly.day"
                />
              </div>
            </div>
            <div class="input-holder">
              <form-check-input
                class="customise color-secondary"
                type="radio"
                name="recurring_type"
                @changed="recurringTypeChanged('monthly')"
                value="monthly"
                :checked="recurringType == 'monthly'"
              >
                {{ $t("packages.update.modal.fields.monthly") }}
              </form-check-input>
              <div v-if="recurringType == 'monthly'" class="datetime holder">
                <Field
                  class="inputStyle time font-size-md"
                  name="time"
                  type="time"
                  rules="required"
                  v-model="monthly.time"
                />

                <validation-error name="time" class="errorMessage" />
                <ErrorMessage name="time" class="errorMessage" />
                <DayDropDown
                  :recurring_type="recurringType"
                  @showHide="showHide"
                  :hidden="hidden"
                  :Days="DAY"
                  :theDay="monthly.day"
                />
              </div>
            </div>
          </div>
          <validation-error name="name" class="errorMessage" />
          <ErrorMessage name="name" class="errorMessage" />
          <button type="submit" class="btn btn-primary p-2 mt-3">
            {{ $t("packages.update.modal.buttons.save") }}
          </button>
        </div>
      </Form>
      <button
        @click="saveLater"
        ref="closeOnUpdateRecurrings"
        data-bs-dismiss="modal"
        type="text"
        class="btn btn-default w-100"
      >
        {{ $t("global.cancel") }}
      </button>
    </template>
    <button ref="updatePackageRecurrings" class="d-none"></button>
  </confirm-modal>

  <!-- Currency Modal -->
  <confirm-modal
    modalDialogClasses="modal-notice"
    :title="$t('packages.update.currency.modal.title')"
    declinedBtnClass="btn-default"
    :closeButton="true"
    :showButtons="false"
  >
    <template #body>
      <div class="body-holder color-secondary font-size-md mt-3">
        {{ $t("packages.update.currency.modal.body") }}
      </div>
      <Form v-on:submit="updatePackageCurrency" class="new-package-add">
        <div class="form-container">
          <perfect-scrollbar :options="{ wheelPropagation: false }">
            <div class="inputContainer customise">
              <div
                v-for="currency in currencies"
                class="input-holder"
                :key="currency"
              >
                <form-check-input
                  class="customise"
                  type="radio"
                  name="recurring_type"
                  :checked="currency == single_package?.preferred_currency"
                  value="onetime"
                  @changed="currencyChange(currency)"
                >
                  {{ currency }}
                </form-check-input>
              </div>
            </div>
          </perfect-scrollbar>
          <validation-error name="name" class="errorMessage" />
          <ErrorMessage name="name" class="errorMessage" />
          <button
            ref="packageCurrencySubmit"
            type="submit"
            class="btn btn-primary p-2 mt-3"
          >
            {{ $t("global.save") }}
          </button>
        </div>
      </Form>
      <button
        @click="saveLater"
        ref="closeOnCurrencyUpdate"
        data-bs-dismiss="modal"
        type="text"
        class="btn btn-default w-100"
      >
        {{ $t("global.cancel") }}
      </button>
    </template>
    <button ref="updatePackageCurrency" class="d-none"></button>
  </confirm-modal>

  <!-- Address Modal -->
  <confirm-modal
    modalDialogClasses="modal-notice"
    :title="$t('packages.update.address.modal.title')"
    declinedBtnClass="btn-default"
    :closeButton="true"
    :showButtons="false"
  >
    <template #body>
      <div class="body-holder color-secondary font-size-md mt-3">
        {{ $t("packages.update.address.modal.body") }}
      </div>
      <Form v-on:submit="updatePackageAddress" class="new-package-add">
        <div class="form-container">
          <perfect-scrollbar :options="{ wheelPropagation: false }">
            <div class="inputContainer customise">
              <div
                v-for="address in addresses"
                class="input-holder"
                :key="address.id"
              >
                <form-check-input
                  class="customise"
                  type="radio"
                  name="recurring_type"
                  :checked="address.id == single_package?.address.id"
                  value="onetime"
                  @changed="addressChange(address)"
                >
                  <div class="w-75">
                    {{ address.address_description }}
                  </div>
                </form-check-input>
              </div>
            </div>
          </perfect-scrollbar>
          <validation-error name="name" class="errorMessage" />
          <ErrorMessage name="name" class="errorMessage" />
          <button
            ref="packageAddressSubmit"
            type="submit"
            class="btn btn-primary p-2 mt-3"
          >
            {{ $t("global.save") }}
          </button>
        </div>
      </Form>
      <button
        @click="saveLater"
        ref="closeOnAddressUpdate"
        data-bs-dismiss="modal"
        type="text"
        class="btn btn-default w-100"
      >
        {{ $t("global.cancel") }}
      </button>
    </template>
    <button ref="updatePackageAddress" class="d-none"></button>
  </confirm-modal>
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal.vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import CustomInput from "@/mixins/CustomInput";
import { mapActions, mapGetters } from "vuex";
import ValidationError from "@/components/ValidationError.vue";
import FormCheckInput from "@/components/FormCheckInput";
import DayDropDown from "@/views/Packages/partials/DayDropDown.vue";

import {
  WEEK,
  DAY,
  formatDateTime,
  formatTimeToUtc,
  formatWeekDay,
  formatDay,
  gmtDateTime,
  seperateDateTime,
  formatTimeToGmt,
} from "@/includes/formatDateTime";
export default {
  components: {
    ConfirmModal,
    Field,
    Form,
    ErrorMessage,
    ValidationError,
    FormCheckInput,
    DayDropDown,
  },
  name: "UpdateModals",
  props: {
    deleteModal: {
      type: Boolean,
    },
    nameUpdateProp: {
      type: Boolean,
    },
    recurringUpdateProp: {
      type: Boolean,
    },
    addressUpdateProp: {
      type: Boolean,
    },
    currencyUpdateProp: {
      type: Boolean,
    },
  },
  mixins: [CustomInput],
  data() {
    return {
      modelValue: "",
      placeholder: "",
      minDate: this.getDate(),
      WEEK: WEEK,
      DAY: DAY,
      hidden: "hidden",
      recurringType: null,
      oneTime: {
        date: null,
        time: null,
      },
      currencies: null,
      addresses: null,
      selected_currency: null,
      selected_address: null,
      weekly: {
        day: null,
        time: null,
      },
      monthly: {
        day: null,
        time: null,
      },
    };
  },
  methods: {
    ...mapActions(["deletePack", "packageUpdate", "getPackage"]),
    deletePackage() {
      this.deletePack(this.single_package.id);
    },
    async updateName(e) {
      this.packageUpdateReplicate.packageNameUpdate.name = e.name;
      this.packageUpdateReplicate.packageNameUpdate.address_id =
        this.packageUpdateReplicate.packageNameUpdate.address.id;
      this.$refs.closeOnSubmit.click();
      await this.packageUpdate(this.packageUpdateReplicate.packageNameUpdate);
      if (this.packUpd == "success") {
        this.single_package.name = e.name;
      } else if (this.packUpd == "error") {
        this.packageUpdateReplicate.packageNameUpdate.name =
          this.single_package.name;
      }
    },
    showHide() {
      if (this.hidden == "rotate") {
        this.hidden = "hidden";
      } else {
        this.hidden = "rotate";
      }
    },
    getDate() {
      let date = new Date();
      let day = date.getDate().toString();
      let month = (date.getMonth() + 1).toString();
      let year = date.getFullYear().toString();
      if (day.length == 1) {
        day = "0" + day;
      }
      if (month.length == 1) {
        month = "0" + month;
      }
      return year + "-" + month + "-" + day;
    },
    async updatePackageRecurrings(e) {
      this.$refs.closeOnUpdateRecurrings.click();
      await this.prepareUpdateData(e);
      this.packageUpdateReplicate.packageRecurring.recurring_type =
        this.recurringType;
      this.packageUpdate(this.packageUpdateReplicate.packageRecurring);
    },
    async prepareUpdateData(formData) {
      this.packageUpdateReplicate.packageRecurring.address_id =
        this.packageUpdateReplicate.packageRecurring.address.id;
      if (this.recurringType == "onetime") {
        this.packageUpdateReplicate.packageRecurring.datetime = formatDateTime(
          formData.date,
          formData.time
        );
      } else if (this.recurringType == "weekly") {
        let formatTimeToUtc_var = formatTimeToUtc(formData.time);
        this.packageUpdateReplicate.packageRecurring.time =
          formatTimeToUtc_var.utc_time;
        let difference = formatTimeToUtc_var.utc_day - formatTimeToUtc_var.day;
        this.packageUpdateReplicate.packageRecurring.day = formatWeekDay(
          difference,
          this.week.dayValue
        );
      } else if (this.recurringType == "monthly") {
        let formatTimeToUtc_var = formatTimeToUtc(formData.time);
        this.packageUpdateReplicate.packageRecurring.time =
          formatTimeToUtc_var.utc_time;
        let difference = formatTimeToUtc_var.utc_day - formatTimeToUtc_var.day;
        this.packageUpdateReplicate.packageRecurring.day = formatDay(
          difference,
          this.day.dayValue
        );
      }
    },
    prepareDateTimeGmt(param, object) {
      if (param === "onetime") {
        let dateTime = gmtDateTime(object.datetime);
        this.oneTime.date = seperateDateTime(dateTime).date;
        this.oneTime.time = seperateDateTime(dateTime).time;
      } else if (param === "weekly") {
        let formatTimeToGmt_var = formatTimeToGmt(object.time);
        this.weekly.time = formatTimeToGmt_var.gmt_time;
        let difference = formatTimeToGmt_var.day - formatTimeToGmt_var.utc_day;
        this.weekly.day = formatWeekDay(difference, object.day);
      } else if (param === "monthly") {
        let formatTimeToGmt_var = formatTimeToGmt(object.time);
        this.monthly.time = formatTimeToGmt_var.gmt_time;
        let difference = formatTimeToGmt_var.day - formatTimeToGmt_var.utc_day;
        this.monthly.day = formatDay(difference, object.day);
      }
    },
    recurringTypeChanged(recurring_type) {
      this.recurringType = recurring_type;
    },
    // Currencies
    currencyChange(currency) {
      this.selected_currency = currency;
    },
    async updatePackageCurrency() {
      this.$refs.packageCurrencySubmit.classList.add("disabled");
      this.packageUpdateReplicate.packageCurrency.address_id =
        this.packageUpdateReplicate.packageCurrency.address.id;
      this.packageUpdateReplicate.packageCurrency.preferred_currency =
        this.selected_currency;
      await this.packageUpdate(this.packageUpdateReplicate.packageCurrency);
      await this.getPackage(this.packageUpdateReplicate.packageCurrency.id);
      this.$refs.closeOnCurrencyUpdate.click();
      this.$refs.packageCurrencySubmit.classList.remove("disabled");
    },
    // Currencies
    addressChange(address) {
      this.selected_address = address;
    },
    async updatePackageAddress() {
      this.$refs.packageAddressSubmit.classList.add("disabled");
      this.packageUpdateReplicate.packageAddress.address_id =
        this.selected_address.id;
      await this.packageUpdate(this.packageUpdateReplicate.packageAddress);
      this.packageUpdateReplicate.packageAddress.address =
        this.selected_address;
      this.$refs.closeOnAddressUpdate.click();
      this.$refs.packageAddressSubmit.classList.remove("disabled");
    },
  },
  computed: {
    ...mapGetters({
      packDel: "packageDelete",
      packUpd: "packageUpdate",
      single_package: "packageShow",
      packageUpdateReplicate: "packageUpdateReplicate",
      day: "getDay",
      week: "getWeek",
    }),
  },
  watch: {
    deleteModal: {
      handler() {
        if (this.deleteModal == true) {
          this.$refs.deleteButton.click();
        }
      },
    },
    nameUpdateProp: {
      handler() {
        if (this.nameUpdateProp == true) {
          this.$refs.updateName.click();
        }
      },
    },
    recurringUpdateProp: {
      handler() {
        if (this.recurringUpdateProp == true) {
          this.$refs.updatePackageRecurrings.click();
        }
      },
    },
    addressUpdateProp: {
      handler() {
        if (this.addressUpdateProp == true) {
          this.$refs.updatePackageAddress.click();
        }
      },
    },
    currencyUpdateProp: {
      handler() {
        if (this.currencyUpdateProp == true) {
          this.$refs.updatePackageCurrency.click();
        }
      },
    },
    packDel: {
      handler() {
        if (this.packDel == "success") {
          this.$router.replace("/");
          this.$router.push("/packages");
        }
      },
    },
    single_package: {
      handler() {
        //name
        this.modelValue = this.packageUpdateReplicate.packageNameUpdate.name;
        this.placeholder = this.packageUpdateReplicate.packageNameUpdate.name;
        //recurrings
        this.recurringType =
          this.packageUpdateReplicate.packageRecurring.recurring_type;
        this.prepareDateTimeGmt(
          this.recurringType,
          this.packageUpdateReplicate.packageRecurring
        );
        //currencies
        this.currencies =
          this.packageUpdateReplicate.packageCurrency.available_currencies;
        this.selected_currency =
          this.packageUpdateReplicate.packageCurrency.preferred_currency;

        // Addresses
        this.addresses =
          this.packageUpdateReplicate.packageAddress.available_addresses;
        this.selected_address =
          this.packageUpdateReplicate.packageAddress.address;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.new-package-add {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .inputBox {
    max-width: 310px;
    width: 100%;
    margin-bottom: 20px;
    padding-top: map-get($spacings, "medium");
  }
  .form-container {
    display: flex;
    flex-direction: column;
    gap: map-get($spacings, "xsmall");
    & .ps {
      max-height: 300px;
      .inputContainer {
        flex-direction: column;

        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        border-bottom: 1px solid map-get($colors, "gray");
        padding-top: map-get($spacings, "medium");
        &.customise {
          gap: map-get($spacings, "medium");
          border-bottom: 0px;
        }
        .inputStyle {
          cursor: pointer;
          color: map-get($colors, "black");
          border: none;
          width: 100%;
          height: 30px;
          line-height: 16px;
          background-color: transparent;
          &:focus {
            border: none;
            outline: none;
          }
          &:active {
            background-color: transparent;
          }
        }

        .labelStyle {
          position: absolute;
          bottom: 5px;
          font-size: 16px;
          transition: ease-in-out 0.2s;
          color: map-get($colors, "secondary");
          font-weight: 400;
        }

        .inFocusInput {
          bottom: 26px;
          font-size: 14px;
          transition: ease-in-out 0.2s;
        }
        .input-holder {
          border-bottom: 1px solid rgb(213, 213, 213, 0.5);
          padding-bottom: map-get($spacings, "medium");
          &:last-child {
            border-bottom: 0px;
          }
          .datetime.holder {
            padding-top: map-get($spacings, "medium");
            display: flex;
            flex-direction: column;
            gap: map-get($spacings, "default");
            .inputStyle {
              border: 1px solid map-get($colors, "bordering-gray");
              border-radius: 8px;
              padding: 0 map-get($spacings, "small");
              height: 50px;
              &::-webkit-calendar-picker-indicator {
                // background: orange;
                background-size: cover;
              }
              &.time {
                &::-webkit-calendar-picker-indicator {
                  // background: orange;
                  background-size: 129% 129%;
                }
              }
            }
          }
        }
      }
    }
    .inputContainer {
      flex-direction: column;

      width: 100%;
      height: auto;
      position: relative;
      display: flex;
      border-bottom: 1px solid map-get($colors, "gray");
      padding-top: map-get($spacings, "medium");
      &.customise {
        gap: map-get($spacings, "medium");
        border-bottom: 0px;
      }
      .inputStyle {
        cursor: pointer;
        color: map-get($colors, "black");
        border: none;
        width: 100%;
        height: 30px;
        line-height: 16px;
        background-color: transparent;
        &:focus {
          border: none;
          outline: none;
        }
        &:active {
          background-color: transparent;
        }
      }

      .labelStyle {
        position: absolute;
        bottom: 5px;
        font-size: 16px;
        transition: ease-in-out 0.2s;
        color: map-get($colors, "secondary");
        font-weight: 400;
      }

      .inFocusInput {
        bottom: 26px;
        font-size: 14px;
        transition: ease-in-out 0.2s;
      }
      .input-holder {
        border-bottom: 1px solid rgb(213, 213, 213, 0.5);
        padding-bottom: map-get($spacings, "medium");
        &:last-child {
          border-bottom: 0px;
        }
        .datetime.holder {
          padding-top: map-get($spacings, "medium");
          display: flex;
          flex-direction: column;
          gap: map-get($spacings, "default");
          .inputStyle {
            border: 1px solid map-get($colors, "bordering-gray");
            border-radius: 8px;
            padding: 0 map-get($spacings, "small");
            height: 50px;
            &::-webkit-calendar-picker-indicator {
              // background: orange;
              background-size: cover;
            }
            &.time {
              &::-webkit-calendar-picker-indicator {
                // background: orange;
                background-size: 129% 129%;
              }
            }
          }
        }
      }
    }
    .errorMessage {
      color: red;
      left: 0;
    }
  }
}
</style>