<template>
  <div class="edit-holder">
    <div class="package-name font-size-sm">
      {{ title }}
    </div>
    <div class="info">
      <div v-bind="$attrs" class="name font-size-md">{{ content }}</div>
      <div
        @click="$emit('updatePackage')"
        class="cursor-pointer edit-icon"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Edit",
  data() {
    return {};
  },
  emits: ["updatePackage"],
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      single_package: "packageShow",
    }),
  },
};
</script>

<style lang="scss" scoped>
.edit-holder {
  .warning {
    color: map-get($colors, "warning");
  }
  max-width: 310px;
  width: 100%;

  display: flex;
  // gap: map-get($spacings, "small");
  flex-direction: column;
  padding-right: map-get($spacings, "medium");
  @include media-breakpoint-down(md) {
    max-width: 100%;
    padding-right: 0px;
  }
  .package-name {
    color: map-get($colors, "secondary");
  }
  &.warning {
    .package-name {
      color: map-get($colors, "warning");
    }
  }
  .info {
    justify-content: space-between;
    display: flex;
    .name {
      width: 100%;
    }
    .edit-icon {
      background-image: url("../../../assets/edit.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
    }
  }
}
</style>