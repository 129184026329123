<template>
  <div class="bg-container">
    <div
      v-if="single_package != null"
      :style="{ top: headerHeight + 'px' }"
      class="the-package"
    >
      <div class="container">
        <package-template class="pb-3 pt-3 border-bottom-color">
          <package-color :color="single_package?.name" />
          <package-card-content>
            <PackageBadge
              class="d-none d-md-block"
              :enabled="single_package?.status"
            />
            <div class="package-activity" :class="showHideClass">
              <div class="package-name font-size-md dotdotdot lines-2">
                {{ single_package?.name }}
              </div>
            </div>
            <div class="products font-size-default" :class="showHideClass">
              {{ $t("packages.index.products") }}
              <div class="numer">
                {{ single_package?.package_products?.length }}
              </div>
            </div>
          </package-card-content>
          <package-icon>
            <div class="icons">
              <div class="action-icons" :class="showHideClass">
                <div
                  @click="cancel"
                  class="holder cursor-pointer cancel font-size-md"
                  ref="cancel"
                >
                  {{ $t("global.cancel").toUpperCase() }}
                </div>
                <div
                  ref="savePackageNameRef"
                  @click="saveName"
                  class="holder cursor-pointer"
                >
                  <div
                    @click="editPackageName"
                    class="save-icon"
                    :class="showHideClass"
                  ></div>
                  <div class="save font-size-md">
                    {{ $t("global.save").toUpperCase() }}
                  </div>
                </div>
              </div>

              <div class="holder">
                <div
                  @click="editPackageName"
                  class="edit-icon cursor-pointer"
                  :class="showHideClass"
                ></div>
              </div>
              <div class="holder">
                <div
                  @click="deleteEvent"
                  class="trash-icon cursor-pointer"
                ></div>
              </div>
            </div>
          </package-icon>
        </package-template>
        <Form
          v-on:submit="updateName"
          class="new-package-add"
          :class="showHideClass"
        >
          <div class="form-container">
            <div class="inputContainer">
              <label
                class="labelStyle"
                :class="{ inFocusInput: isFocused }"
                :for="id"
              >
                {{ $t("packages.edit.modal.name.label") }}
              </label>

              <Field
                rules="required"
                :id="id"
                class="inputStyle font-size-md"
                @focus="onFocus"
                type="text"
                v-model="modelValue"
                @blur="onBlur"
                name="name"
                :placeholder="placeholder"
              />
            </div>
            <validation-error name="name" class="errorMessage" />
            <ErrorMessage name="name" class="errorMessage" />
            <button
              ref="submitSavePackageName"
              type="submit"
              class="d-none btn btn-primary p-2 mt-3"
            >
              {{ $t("packages.edit.modal.name.button") }}
            </button>
          </div>
        </Form>
      </div>
    </div>

    <div :style="{ top: headerHeight + 'px' }" class="the-package" v-else>
      <div class="container">
        <div class="image-text-holder">
          <div class="image ratio ratio-4_3">
            <div class="body skeleton_loader"></div>
          </div>
          <div class="text-holder">
            <div class="skeleton w-100">
              <div class="line skeleton_loader dark medium w-80"></div>
            </div>
            <div class="skeleton">
              <div class="line skeleton_loader dark thick w-50px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container package">
      <package-updates :deleteEventProp="deletePackage"> </package-updates>
      <hr />
      <package-products class="pt-5"></package-products>
    </div>
  </div>
</template>

<script>
import PackageUpdates from "@/views/Packages/partials/PackageUpdates.vue";
import PackageProducts from "@/views/Packages/partials/PackageProducts.vue";
import PackageTemplate from "@/components/Packages/parts/PackageTemplate.vue";
import PackageColor from "@/components/Packages/parts/PackageColor.vue";
import PackageCardContent from "@/components/Packages/parts/PackageCardContent.vue";
import PackageIcon from "@/components/Packages/parts/PackageIcon.vue";
import PackageBadge from "@/components/Packages/parts/PackageBadge.vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import CustomInput from "@/mixins/CustomInput";
import ValidationError from "@/components/ValidationError.vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  components: {
    PackageUpdates,
    PackageProducts,
    PackageTemplate,
    PackageColor,
    PackageCardContent,
    PackageIcon,
    PackageBadge,
    Field,
    Form,
    ErrorMessage,
    ValidationError,
  },
  name: "PackagesShow",
  setup(){
    const route = useRoute();
    const {t} = useI18n() // use as global scope
    useMeta({
      title: route.query.title ?? t("packages.show.page_title"),
    })
  },
  mixins: [CustomInput],
  data() {
    return {
      id: this.$route.params.id,
      package: null,
      headerHeight: null,
      deletePackage: false,
      showHideClass: "",
      modelValue: "",
      placeholder: "",
    };
  },
  methods: {
    ...mapActions(["getPackage", "resetPackageVal", "packageUpdate"]),
    onResize() {
      this.headerHeight = document.getElementById("header").offsetHeight;
    },
    deleteEvent: function () {
      this.deletePackage = true;
      setTimeout(() => {
        this.deletePackage = false;
      }, 500);
    },
    editPackageName: function () {
      this.showHideClass = "save";
    },
    cancel: function () {
      this.showHideClass = "";
    },
    saveName: function () {
      this.$refs.submitSavePackageName.click();
    },
    async updateName(e) {
      this.$refs.savePackageNameRef.classList.add("disabled");
      this.single_package.name = e.name;
      this.single_package.address_id = this.single_package.address.id;
      await this.packageUpdate(this.single_package);
      this.$refs.savePackageNameRef.classList.remove("disabled");
      this.$refs.cancel.click();
    },
  },
  computed: {
    ...mapGetters({ single_package: "packageShow" }),
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.headerHeight = document.getElementById("header").offsetHeight;
  },
  watch: {
    packageShow: {
      immediate: true,
      async handler() {
        await this.getPackage(this.id).then(
          () => {
            this.modelValue = this.single_package.name;
            this.placeholder = this.single_package.name;
          },
          () => {
            //Redirect to 404
            this.$router.replace("/404");
          }
        );
        //name
      },
    },
    $route: function () {
      this.resetPackageVal();
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-container {
  height: 100%;
  flex-direction: column;
  display: flex;
  background: linear-gradient(
    to right,
    map-get($colors, "lightGray") 50%,
    map-get($colors, "white") 50%
  );
  @include media-breakpoint-down(md) {
    background: map-get($colors, "white");
  }
  .the-package {
    display: none;
    z-index: 20;
    @include media-breakpoint-down(md) {
      width: 100%;
      background-color: map-get($colors, "white");
      position: sticky;
      display: block;
      border-bottom: 1px solid map-get($colors, "bordering-gray");
    }
    .package-activity {
      &.save {
        display: none;
      }
      .package-name {
        display: none;
        @include media-breakpoint-down(md) {
          display: flex;
        }
        font-weight: 700;
      }
      .products {
        &.save {
          display: none;
        }
        color: map-get($colors, "secondary");
        opacity: 0.6;
        display: flex;
        gap: 2px;
        .number {
          font-weight: 700;
        }
      }
    }
    .icons {
      display: flex;
      .holder {
        &.cancel {
          color: map-get($colors, "warning");
        }
        & .save {
          color: map-get($colors, "blue");
        }
        padding: 0px map-get($spacings, "small");
        display: flex;
        align-items: center;
        gap: map-get($spacings, "xsmall");
        &:last-child {
          border-left: 1px solid map-get($colors, "bordering-gray");
        }
        .edit-icon {
          width: 20px;
          height: 20px;
          background-image: url("../../assets/edit_address.svg");
          background-repeat: no-repeat;
          background-size: contain;
          &.save {
            display: none;
          }
        }
        .trash-icon {
          width: 20px;
          height: 20px;
          background-image: url("../../assets/bin.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
        .save-icon {
          width: 20px;
          height: 20px;
          background-image: url("../../assets/save.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .action-icons {
        display: none;
        &.save {
          display: flex;
          align-items: center;
        }
        .holder {
          border-left: none;
          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
    }
    .container {
      .new-package-add {
        padding-bottom: map-get($spacings, "small");
        display: none;
        &.save {
          display: block;
        }
        .form-container {
          display: flex;
          flex-direction: column;
          gap: map-get($spacings, "xsmall");
          & .ps {
            max-height: 300px;
            .inputContainer {
              flex-direction: column;
              width: 100%;
              height: auto;
              position: relative;
              display: flex;
              border-bottom: 1px solid map-get($colors, "black");
              &.customise {
                gap: map-get($spacings, "medium");
                border-bottom: 0px;
              }
              .inputStyle {
                cursor: pointer;
                color: map-get($colors, "black");
                border: none;
                width: 100%;
                height: 30px;
                line-height: 16px;
                background-color: transparent;
                &:focus {
                  border: none;
                  outline: none;
                }
                &:active {
                  background-color: transparent;
                }
              }

              .labelStyle {
                position: absolute;
                bottom: 5px;
                font-size: 16px;
                transition: ease-in-out 0.2s;
                color: map-get($colors, "secondary");
                font-weight: 400;
              }

              .inFocusInput {
                bottom: 26px;
                font-size: 14px;
                transition: ease-in-out 0.2s;
              }
              .input-holder {
                border-bottom: 1px solid rgb(213, 213, 213, 0.5);
                padding-bottom: map-get($spacings, "medium");
                &:last-child {
                  border-bottom: 0px;
                }
                .datetime.holder {
                  padding-top: map-get($spacings, "medium");
                  display: flex;
                  flex-direction: column;
                  gap: map-get($spacings, "default");
                  .inputStyle {
                    border: 1px solid map-get($colors, "bordering-gray");
                    border-radius: 8px;
                    padding: 0 map-get($spacings, "small");
                    height: 50px;
                    &::-webkit-calendar-picker-indicator {
                      // background: orange;
                      background-size: cover;
                    }
                    &.time {
                      &::-webkit-calendar-picker-indicator {
                        // background: orange;
                        background-size: 129% 129%;
                      }
                    }
                  }
                }
              }
            }
          }
          .inputContainer {
            flex-direction: column;
            width: 100%;
            height: auto;
            position: relative;
            display: flex;
            border-bottom: 1px solid map-get($colors, "black");
            // padding-top: map-get($spacings, "medium");
            &.customise {
              gap: map-get($spacings, "medium");
              border-bottom: 0px;
            }
            .inputStyle {
              cursor: pointer;
              color: map-get($colors, "black");
              border: none;
              width: 100%;
              height: 30px;
              line-height: 16px;
              background-color: transparent;
              &:focus {
                border: none;
                outline: none;
              }
              &:active {
                background-color: transparent;
              }
            }

            .labelStyle {
              position: absolute;
              bottom: 5px;
              font-size: 16px;
              transition: ease-in-out 0.2s;
              color: map-get($colors, "secondary");
              font-weight: 400;
            }

            .inFocusInput {
              bottom: 26px;
              font-size: 14px;
              transition: ease-in-out 0.2s;
            }
            .input-holder {
              border-bottom: 1px solid rgb(213, 213, 213, 0.5);
              padding-bottom: map-get($spacings, "medium");
              &:last-child {
                border-bottom: 0px;
              }
              .datetime.holder {
                padding-top: map-get($spacings, "medium");
                display: flex;
                flex-direction: column;
                gap: map-get($spacings, "default");
                .inputStyle {
                  border: 1px solid map-get($colors, "bordering-gray");
                  border-radius: 8px;
                  padding: 0 map-get($spacings, "small");
                  height: 50px;
                  &::-webkit-calendar-picker-indicator {
                    // background: orange;
                    background-size: cover;
                  }
                  &.time {
                    &::-webkit-calendar-picker-indicator {
                      // background: orange;
                      background-size: 129% 129%;
                    }
                  }
                }
              }
            }
          }
          .errorMessage {
            color: red;
            left: 0;
          }
        }
      }
      .image-text-holder {
        padding: map-get($spacings, "small") 0;
        display: flex;
        gap: map-get($spacings, "small");
        .image {
          max-width: 100px;
          max-height: 100px;
        }
        .text-holder {
          width: 100%;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: map-get($spacings, "small");
        }
      }
    }
  }
  .package {
    display: flex;
    height: 100%;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      flex-wrap: nowrap;
      overflow: auto;
    }
  }
}
</style>
