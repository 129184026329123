<template>
  <div class="prescription-files row">
    <!--  Each Image-->
    <div class="col-4" v-bind="$attrs" v-for="(file, key) in files" :key="key">
      <div class="prescription-file ratio">
        <div class="body">
          <div class="remove Aligner" @click="removeFile(key)">
            <i class="fas fa-times-circle"></i>
          </div>
          <img v-if="file['name'] != null" v-bind:src="file['thumbnail']" />
          <img v-else v-bind:src="websiteUrl + file['thumbnail']" />
        </div>
      </div>
    </div>

    <div class="col-4" v-bind="$attrs">
      <div class="uploader ratio">
        <div class="body">
          <cart-prescription-upload v-model="files" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartPrescriptionUpload from "@/views/Cart/partials/CartPrescriptionUpload";

export default {
  name: "CartPrescriptionFiles",
  components: { CartPrescriptionUpload },
  emits: ["removeFileEmitter"],
  props: {
    modelValue: {
      type: Object
    },
    websiteUrl: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      files: []
    };
  },
  methods: {
    removeFile(key) {
      this.files.splice(key, 1);
      this.$emit("update:modelValue", this.files);
      this.$emit("removeFileEmitter", key);
    },
    updateVModel() {
      this.$emit("update:modelValue", this.files);
    }
  },
  created() {
    this.files = this.modelValue;
  },
  inheritAttrs: false
};
</script>

<style scoped lang="scss">
.prescription-files {
  max-width: 100%;
  padding-top: map-get($spacings, "small");
  padding-bottom: map-get($spacings, "default");
  margin-left: -(map-get($spacings, "small"));
  margin-right: -(map-get($spacings, "small"));

  [class^="col-"] {
    padding-left: map-get($spacings, "small");
    padding-right: map-get($spacings, "small");
  }

  .prescription-file {
    padding: map-get($spacings, "xsmall");
    margin-bottom: map-get($spacings, "default");

    .remove {
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 18px;
      width: 20px;
      height: 20px;
      z-index: 1;
      background-color: #fff;
      border-radius: 20px;

      &:hover {
        cursor: pointer;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: map-get($defaults, "border-radius");
    }
  }
}
</style>
