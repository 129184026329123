<template>
  <div class="prescription-file">
    <label :for="'file_' + unique_id" class="Aligner">
      <i v-if="!uploading" class="fas fa-camera"></i>
      <img src="@/assets/loading.gif" v-if="uploading" alt="Loading" />
    </label>
    <input
      type="file"
      :id="'file_' + unique_id"
      ref="file"
      v-on:change="handleFileChange()"
    />
  </div>
</template>

<script>
import { getBase64, thumbnailify } from "@/includes/base64";

export default {
  name: "CartPrescriptionUpload",
  props: {
    modelValue: {
      type: Object
    }
  },
  data() {
    return {
      unique_id: Math.floor(Math.random() * (9999 - 1000)) + 1000,
      uploading: false,
      file: ""
    };
  },
  methods: {
    // Handle File Change
    handleFileChange() {
      if (this.$refs.file.files[0] != undefined) {
        this.file = this.$refs.file.files[0];
        this.submitFile();
      }
    },
    // Submits the file to the server
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      this.uploading = true;

      window.axios
        .post(window.Routes.getRoute("upload_media"), formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(({ data }) => {
          this.uploading = false;
          getBase64(this.file).then(base64 => {
            thumbnailify(base64, 50).then(base64Thumbnail => {
              this.updateVModel({
                name: data.name,
                thumbnail: base64Thumbnail
              });
            });
          });
        })
        .catch(() => {
          this.updateVModel(null);
        });
    },
    updateVModel(image) {
      let files = this.modelValue;
      files.push(image);
      this.$emit("update:modelValue", files);
      this.$emit("filesUpdated", files);
    }
  }
};
</script>

<style lang="scss" scoped>
.prescription-file {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: map-get($defaults, "border-radius");
  overflow: hidden;
  border: 1px solid map-get($colors, "gray");

  label {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: map-get($colors, "lightGray");
    font-size: 30px;
    color: map-get($colors, "secondary");

    &:hover {
      cursor: pointer;
    }

    img {
      width: 30px;
    }
  }
}
</style>
