<template>
  <div class="cart-prescription-uploader">
    <cart-prescription-files
      :websiteUrl="websiteUrl"
      v-model="files"
      @change="updateVModel"
      :class="theClass"
      @removeFileEmitter="removeFiles"
    />
  </div>
</template>

<script>
import CartPrescriptionFiles from "@/views/Cart/partials/CartPrescriptionFiles";
export default {
  name: "CartPrescriptionUploader",
  components: { CartPrescriptionFiles },
  emits: ["removeFile"],
  props: {
    modelValue: {
      type: Object,
    },
    websiteUrl: {
      type: String,
      default: null,
    },
    theClass: {
      type: String,
    },
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    updateVModel() {
      this.$emit("update:modelValue", this.files);
    },
    removeFiles(key) {
      this.$emit("removeFile", key);
    },
  },
  created() {
    this.files = this.modelValue;
  },
};
</script>

<style scoped>
</style>
