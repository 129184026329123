<template>
  <div class="products-holder">
    <div v-if="single_package != null" class="shop-holder">
      <div v-if="websiteUrl != null" class="logo">
        <img :src="websiteUrl + single_package?.shop.logo.thumbnail" />
      </div>
      <div class="name font-size-big">
        {{ single_package?.shop.name }}
      </div>
    </div>
    <div class="shop-holder" v-else>
      <div class="image ratio ratio-4_3">
        <div class="body skeleton_loader"></div>
      </div>
      <div class="skeleton w-100">
        <div class="line skeleton_loader dark thick w-20"></div>
      </div>
    </div>
    <div v-if="single_package?.package_products.length != 0" class="products">
      <div
        v-for="(package_product, index) in single_package?.package_products"
        :key="package_product.id"
        class="product-holder"
        :id="'product-' + index"
      >
        <div class="product-image-holder">
          <div class="product-image">
            <img :src="websiteUrl + package_product.product.image.thumbnail" />
            <div
              @click="
                removeProduct(single_package.id, package_product.id, index)
              "
              class="cursor-pointer remove-product"
              :id="'remove-product-' + index"
            ></div>
          </div>
          <div class="product-name">
            <div class="name font-weight-bold">
              {{ package_product.product.name }}
            </div>
            <div class="quantity font-weight-bold color-secondary">
              x{{ package_product.quantity }}
              <div class="money font-weight-bold color-black">
                {{
                  formatedPriceProduct(
                    single_package?.preferred_currency,
                    package_product
                  )
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="product-details-holder font-size-medium">
          <div class="product-name">
            <div class="name font-weight-bold">
              {{ package_product.product.name }}
            </div>
            <div class="quantity font-weight-bold color-secondary">
              x{{ package_product.quantity }}
              <div class="money font-weight-bold color-black">
                {{
                  formatedPriceProduct(
                    single_package?.preferred_currency,
                    package_product
                  )
                }}
              </div>
            </div>
          </div>
          <div class="variants-extras">
            <div class="variants-holder">
              <div class="preparing-time">
                <img src="../../../assets/clock.svg" />
                <div class="text color-secondary">
                  {{ $t("packages.products.preparing_time") }}
                </div>
                <div class="time">
                  {{ package_product.product.delay }}
                  <div class="min">{{ $t("packages.products.min") }}</div>
                </div>
              </div>
              <div
                class="variants"
                v-for="product_variant in package_product.product_variants"
                :key="product_variant.id"
              >
                <div
                  class="name color-secondary"
                  v-for="variant in product_variant.variants"
                  :key="variant.id"
                >
                  {{ product_variant.name }}:
                  <div class="color-black">{{ variant.name }}</div>
                </div>
              </div>
            </div>
            <div
              class="extras-holder"
              v-if="package_product.product_extras.length != 0"
            >
              <div class="title color-secondary font-size-md">
                {{ $t("packages.products.extra.title") }}
              </div>
              <div class="extras">
                <div
                  class="extra"
                  v-for="extra in package_product.product_extras"
                  :key="extra.id"
                >
                  <div class="name">{{ extra.name }}</div>
                  <div class="quantity font-weight-bold color-secondary">
                    x{{ extra.quantity }}
                    <div class="money font-weight-bold">
                      {{
                        formatedPrice(
                          single_package?.preferred_currency,
                          extra.price
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-products" v-else>
      <div class="sad-icon">
        <img src="../../../assets/sad.svg" />
      </div>
      <div class="text font-weight-bold font-size-lg">
        {{ $t("packages.empty.title") }}
      </div>
      <div class="subtitle font-size-default color-secondary">
        {{ $t("packages.empty.subtitle") }}
      </div>
      <router-link to="/">
        <a class="px-5 py-2 btn btn-primary font-size-md cursor-pointer">
          {{ $t("packages.empty.explore") }}
        </a>
      </router-link>
    </div>
    <div v-if="single_package != null" class="totality-holder">
      <div v-if="single_package.package_products.length != 0" class="totality">
        <div class="group">
          <div class="weight">
            <div class="title font-size-md color-secondary">
              {{ $t("packages.products.weight") }}
            </div>
            <div class="info font-weight-bold font-size-default">
              {{ parseFloat(package_extra_info?.total_weight).toFixed(2) }}kg
            </div>
          </div>
          <div class="payment-method">
            <div class="title font-size-md color-secondary">
              {{ $t("packages.products.payment_method") }}
            </div>
            <div class="info font-weight-bold font-size-default">
              {{ $t("packages.products.bank") }}
            </div>
          </div>
          <div class="shipping">
            <div class="title font-size-md color-secondary">
              {{ $t("packages.products.shipping") }}
            </div>
            <div class="info font-weight-bold font-size-default">
              {{
                formatedPrice(
                  single_package?.preferred_currency,
                  package_extra_info?.delivery_price
                )
              }}
            </div>
          </div>
        </div>
        <div class="price">
          <div class="title font-size-md color-secondary">
            {{ $t("packages.products.total") }}
          </div>
          <div class="info font-weight-bold font-size-big">
            {{
              formatedPrice(
                single_package?.preferred_currency,
                package_extra_info?.total_price
              )
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="prescriptions">
      <div class="prescription_box" v-if="prescription_required">
        <div class="font-weight-bold text-uppercase color-danger">
          {{ $t("cart.prescription_required") }}
        </div>
        <CartPrescriptionUploader
          :websiteUrl="websiteUrl"
          v-model="prescription_files"
          :theClass="'col-md-3 col-lg-2'"
          @removeFile="prescriptionRemoved"
        />
      </div>
    </div>
    <div class="button-holder">
      <div
        v-if="single_package?.status == true"
        class="font-size-md cursor-pointer btn btn-warning warning"
        @click="deactivate($event)"
      >
        {{ $t("packages.products.btn_active") }}
      </div>
      <div
        v-if="single_package?.status == false"
        class="font-size-md cursor-pointer btn btn-primary primary"
        @click="activate($event)"
      >
        {{ $t("packages.products.btn_passive") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import formatPrice from "@/includes/currency.js";
import CartPrescriptionUploader from "@/views/Cart/partials/CartPrescriptionUploader.vue";
export default {
  name: "PackageProducts",
  components: { CartPrescriptionUploader },
  data() {
    return {
      websiteUrl: null,
      prescription_required: false,
      prescription_files: [],
      prepare_prescriptions_for_upload: [],
    };
  },
  methods: {
    ...mapActions(["packageUpdate"]),
    formatedPriceProduct(currency, package_product) {
      let amount = package_product.product.price;
      for (let product_variant in package_product?.product_variants) {
        for (let variant in package_product?.product_variants[product_variant]
          .variants) {
          amount +=
            package_product?.product_variants[product_variant].variants[variant]
              .extra_price;
        }
      }
      return formatPrice(currency, amount);
    },
    formatedPrice(currency, amount) {
      return formatPrice(currency, amount);
    },
    async prescriptionRemoved(key) {
      this.prepare_prescriptions_for_upload.splice(key, 1);
    },
    async uploadPrescription() {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("package_upload_prescription", {
          package_id: this.single_package.id,
        });
        window.axios
          .post(url, {
            prescription_files: this.prepare_prescriptions_for_upload,
          })
          .then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    async removeProduct(package_id, package_product_id, index) {
      let el = document.getElementById("product-" + index);
      el.style.opacity = 0.3;
      let el2 = document.getElementById("remove-product-" + index);
      el2.style.pointerEvents = "none";
      await this.productRemove(package_id, package_product_id, index, el, el2);
    },
    async productRemove(package_id, package_product_id, index, el, el2) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("package_remove_product", {
          package_id: package_id,
          package_product_id: package_product_id,
        });
        window.axios.delete(url).then(
          (response) => {
            resolve(response.data);
            el.remove();
            this.single_package.package_products.splice(index, 1);
          },
          (error) => {
            reject(error.response.data.errors);
            el.style.opacity = 1;
            el2.style.pointerEvents = "auto";
          }
        );
      });
    },
    async activate(e) {
      e.target.classList.add("disabled");
      let the_package = JSON.parse(JSON.stringify(this.single_package));
      the_package.status = true;
      the_package.address_id = this.single_package.address.id;
      await this.packageUpdate(the_package);
      e.target.classList.remove("disabled");
      this.single_package.status = true;
    },
    async deactivate(e) {
      e.target.classList.add("disabled");
      let the_package = JSON.parse(JSON.stringify(this.single_package));
      the_package.status = false;
      the_package.address_id = this.single_package.address.id;
      await this.packageUpdate(the_package);
      e.target.classList.remove("disabled");
      this.single_package.status = false;
    },
    async removeSpaces() {
      for (let prescription in this.prepare_prescriptions_for_upload) {
        this.prepare_prescriptions_for_upload[prescription] =
          this.prepare_prescriptions_for_upload[prescription].replace(
            / /g,
            "-"
          );
      }
      for (let prescription_file in this.prescription_files) {
        if (this.prescription_files[prescription_file].name != null) {
          this.prescription_files[prescription_file].name =
            this.prescription_files[prescription_file].name.replace(/ /g, "-");
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      single_package: "packageShow",
      package_extra_info: "packageShowExtraInfo",
    }),
  },
  watch: {
    single_package: {
      handler() {
        this.websiteUrl = process.env.VUE_APP_API_URL;
        if (this?.single_package?.package_products != undefined) {
          for (let package_product of this?.single_package?.package_products) {
            if (package_product.product.prescription_required == true) {
              this.prescription_required = true;
            }
          }
          if (this.single_package.length != 0) {
            this.prescription_files = this.single_package.prescription_files;
          }
        }
      },
    },
    prescription_files: {
      async handler() {
        if (
          this.prescription_required === true &&
          this.prescription_files.length != 0
        ) {
          for (let prescription_file in this.prescription_files) {
            if (this.prescription_files[prescription_file].file_name != null) {
              this.prepare_prescriptions_for_upload[prescription_file] =
                this.prescription_files[prescription_file].file_name;
            }
            if (this.prescription_files[prescription_file].name != null) {
              this.prepare_prescriptions_for_upload[prescription_file] =
                this.prescription_files[prescription_file].name;
            }
          }
          await this.uploadPrescription();
          await this.removeSpaces();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.products-holder {
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: map-get($spacings, "large");
  overflow-x: hidden;
  @include media-breakpoint-down(md) {
    overflow: unset;
  }
  width: 100%;
  gap: map-get($spacings, "small");
  background-color: map-get($colors, "white");
  padding-left: map-get($spacings, "large");
  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-top: map-get($spacings, "large") !important;
    padding-bottom: map-get($spacings, "large");
  }
  .shop-holder {
    display: flex;
    gap: map-get($spacings, "small");
    align-items: center;
    border-bottom: 1px solid map-get($colors, "bordering-gray");
    padding-left: map-get($spacings, "small");
    padding-bottom: map-get($spacings, "small");
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
    .logo {
      border-radius: 50%;
      max-width: 50px;
      max-height: 50px;
      img {
        width: 100%;
        height: 100%;
        border: 1px solid map-get($colors, "bordering-gray");
        border-radius: 50%;
      }
    }
    .name {
      font-weight: 700;
    }
    .image {
      max-width: 100px;
      max-height: 100px;
    }
  }
  .products {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: auto;
    @include media-breakpoint-down(md) {
      overflow: unset;
    }
    gap: map-get($spacings, "small");
    .product-holder {
      padding: 0px map-get($spacings, "small");
      padding-top: 3px;
      @include media-breakpoint-down(md) {
        padding: 0;
        padding-bottom: map-get($spacings, "small");
      }
      display: flex;
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
      gap: map-get($spacings, "small");
      padding-bottom: map-get($spacings, "small");
      .product-image-holder {
        @include media-breakpoint-down(md) {
          display: flex;
          justify-content: center;
          gap: map-get($spacings, "xsmall");
          align-items: center;
        }
        .product-image {
          position: relative;
          display: flex;
          max-width: 66px;
          max-height: 66px;
          img {
            border: 1px solid map-get($colors, "bordering-gray");
            border-radius: map-get($borders, "default");
            width: 100%;
            height: 100%;
          }
          .remove-product {
            position: absolute;
            top: -4px;
            right: -5px;
            width: 21px;
            height: 21px;
            background-image: url("../../../assets/remove.svg");
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
        .product-name {
          @include media-breakpoint-down(md) {
            display: flex;
          }
          .name {
            display: flex;
            align-items: center;
          }
          display: none;
          justify-content: space-between;
          width: 100%;
          .quantity {
            display: flex;
            gap: map-get($spacings, "xsmall");
            .money {
              display: flex;
            }
          }
        }
      }

      .product-details-holder {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: map-get($spacings, "xsmall");
        .product-name {
          @include media-breakpoint-down(md) {
            display: none;
          }
          .name {
            display: flex;
            align-items: center;
          }
          display: flex;
          justify-content: space-between;
          width: 100%;
          .quantity {
            display: flex;
            gap: map-get($spacings, "xsmall");
            .money {
              display: flex;
            }
          }
        }
        .variants-extras {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: map-get($spacings, "small");
          .variants-holder {
            display: flex;
            flex-direction: column;
            .preparing-time {
              flex-wrap: wrap;
              display: flex;
              gap: map-get($spacings, "xsmall");
              .time {
                display: flex;
                gap: map-get($spacings, "xsmall");
              }
            }
            .variants {
              .name {
                display: flex;
                gap: map-get($spacings, "xsmall");
              }
            }
          }
          .extras-holder {
            display: flex;
            flex-direction: column;
            gap: map-get($spacings, "xsmall");
            .extras {
              display: flex;
              flex-direction: column;
              .extra {
                display: flex;
                justify-content: space-between;
                width: 100%;
                gap: map-get($spacings, "small");
                .quantity {
                  display: flex;
                  gap: map-get($spacings, "xsmall");
                  .money {
                    display: flex;
                  }
                }
              }
            }
          }
        }
      }
      border-bottom: 1px solid map-get($colors, "bordering-gray");
      &:last-child {
        border-bottom: 0px;
      }
    }
  }
  .no-products {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: map-get($spacings, "small");
    .sad-icon {
      max-height: 70px;
      max-width: 70px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .totality-holder {
    padding: 0px map-get($spacings, "small");
    padding-bottom: map-get($spacings, "large");
    @include media-breakpoint-down(md) {
      padding: 0;
      padding-top: map-get($spacings, "small");
      padding-bottom: map-get($spacings, "large");
    }
    .totality {
      border-top: 2px solid map-get($colors, "bordering-gray");
      padding-top: map-get($spacings, "small");
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .group {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 70%;
        justify-content: space-between;
        gap: map-get($spacings, "small");
      }
      .price {
        display: flex;
        align-items: flex-end;
        justify-self: flex-end;
        flex-direction: column;
      }
    }
  }
  .button-holder {
    display: flex;
    justify-content: flex-end;
    .warning {
      max-width: 250px;
      width: 100%;
    }
    .primary {
    }
  }
  .prescriptions {
  }
}
</style>