<template>
  <div v-if="single_package != null" class="update-container pt-5">
    <div class="package">
      <package-template class="pb-3 border-bottom-color">
        <package-color :color="single_package?.name" />
        <package-card-content>
          <PackageBadge
            class="d-none d-md-block"
            :enabled="single_package?.status"
          />
          <div class="package-activity">
            <div class="package-name font-size-md dotdotdot lines-2">
              {{ single_package?.name }}
            </div>
          </div>
          <div class="products font-size-default">
            {{ $t("packages.index.products") }}
            <div class="numer">
              {{ single_package?.package_products?.length }}
            </div>
          </div>
        </package-card-content>
        <package-icon
          ><div
            @click="deleteEvent()"
            class="icon-delete cursor-pointer d-none d-sm-block"
            ref="trashIconEvent"
          ></div>
        </package-icon>
      </package-template>
    </div>
    <div class="edits">
      <div class="font-weight-bold font-size-default">
        {{ $t("packages.update.title") }}
      </div>
      <div class="holder">
        <edit
          class="d-none d-md-block dotdotdot lines-2"
          @updatePackage="nameUpdate"
          :title="$t('packages.edit.name')"
          :content="single_package?.name"
        />
        <edit
          @updatePackage="addressUpdate"
          :title="$t('packages.edit.shipping')"
          :content="single_package?.address.address_description"
          class="dotdotdot lines-2"
        />
        <edit
          @updatePackage="recurringUpdate"
          :title="$t('packages.edit.receiving')"
          :content="formatContent()"
          class="dotdotdot lines-2"
          :class="single_package?.recurring_type == null ? 'warning' : ''"
        />
        <edit
          @updatePackage="currencyUpdate"
          :title="$t('packages.edit.currency')"
          :content="single_package?.preferred_currency"
          class="dotdotdot lines-2"
        />
      </div>
    </div>
  </div>

  <div class="update-container pt-5" v-else>
    <div class="image-text-holder">
      <div class="image ratio ratio-4_3">
        <div class="body skeleton_loader"></div>
      </div>
      <div class="text-holder">
        <div class="skeleton w-100">
          <div class="line skeleton_loader dark medium w-80"></div>
        </div>
        <div class="skeleton">
          <div class="line skeleton_loader dark thick w-50px"></div>
        </div>
      </div>
    </div>
    <div class="text pt-5">
      <div class="skeleton">
        <div class="line skeleton_loader dark slim w-30"></div>
      </div>
      <div class="skeleton">
        <div class="line skeleton_loader dark thick w-70"></div>
      </div>
    </div>
    <div class="text pt-5">
      <div class="skeleton">
        <div class="line skeleton_loader dark slim w-30"></div>
      </div>
      <div class="skeleton">
        <div class="line skeleton_loader dark thick w-70"></div>
      </div>
    </div>
    <div class="text pt-5">
      <div class="skeleton">
        <div class="line skeleton_loader dark slim w-30"></div>
      </div>
      <div class="skeleton">
        <div class="line skeleton_loader dark thick w-70"></div>
      </div>
    </div>
    <div class="text pt-5">
      <div class="skeleton">
        <div class="line skeleton_loader dark slim w-30"></div>
      </div>
      <div class="skeleton">
        <div class="line skeleton_loader dark thick w-70"></div>
      </div>
    </div>
  </div>
  <update-modals
    :nameUpdateProp="updateName"
    :deleteModal="deleteModal"
    :recurringUpdateProp="updateRecurring"
    :addressUpdateProp="updateAddress"
    :currencyUpdateProp="updateCurrency"
  ></update-modals>
</template>

<script>
import Edit from "@/views/Packages/partials/Edit.vue";
import PackageTemplate from "@/components/Packages/parts/PackageTemplate.vue";
import PackageColor from "@/components/Packages/parts/PackageColor.vue";
import PackageCardContent from "@/components/Packages/parts/PackageCardContent.vue";
import PackageIcon from "@/components/Packages/parts/PackageIcon.vue";
import PackageBadge from "@/components/Packages/parts/PackageBadge.vue";
import UpdateModals from "@/views/Packages/partials/UpdateModals.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Edit,
    PackageTemplate,
    PackageColor,
    PackageCardContent,
    PackageIcon,
    PackageBadge,
    UpdateModals,
  },
  name: "PackageUpdates",
  data() {
    return {
      deleteModal: false,
      updateName: false,
      updateRecurring: false,
      updateAddress: false,
      updateCurrency: false,
    };
  },
  props: {
    deleteEventProp: {
      type: Boolean,
    },
  },
  methods: {
    deleteEvent: function () {
      this.deleteModal = true;
      setTimeout(() => {
        this.deleteModal = false;
      }, 500);
    },
    formatContent: function () {
      if (this.single_package.recurring_type != null) {
        if (this.single_package.recurring_type == "onetime") {
          return (
            this.$t("packages.update.modal.fields.specific") +
            " - " +
            new Date(this.single_package.datetime)
          );
        } else if (this.single_package.recurring_type == "weekly") {
          return (
            this.$t("packages.update.modal.fields.weekly") +
            " - " +
            this.$t("packages.update.modal.dropdown.every") +
            " - " +
            this.$t(
              "packages.update.modal.dropdown.week." + this.single_package.day
            )
          );
        } else if (this.single_package.recurring_type == "monthly") {
          return (
            this.$t("packages.update.modal.fields.monthly") +
            " - " +
            this.$t("packages.update.modal.dropdown.day") +
            " - " +
            this.single_package.day
          );
        }
      } else {
        return this.$t("packages.edit.recurrings.not_set");
      }
    },
    nameUpdate: function () {
      this.updateName = true;
      setTimeout(() => {
        this.updateName = false;
      }, 500);
    },
    recurringUpdate: function () {
      this.updateRecurring = true;
      setTimeout(() => {
        this.updateRecurring = false;
      }, 500);
    },
    addressUpdate: function () {
      this.updateAddress = true;
      setTimeout(() => {
        this.updateAddress = false;
      }, 500);
    },
    currencyUpdate: function () {
      this.updateCurrency = true;
      setTimeout(() => {
        this.updateCurrency = false;
      }, 500);
    },
  },
  computed: {
    ...mapGetters({ single_package: "packageShow" }),
  },
  mounted() {
    this.single_package;
  },
  watch: {
    single_package: {
      handler() {
        //will update the name form on mobile
      },
    },
    deleteEventProp: {
      handler() {
        if (this.deleteEventProp == true) {
          this.$refs.trashIconEvent.click();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.update-container {
  width: 33%;
  @include media-breakpoint-down(md) {
    padding-top: 0px !important;
    width: 100%;
  }
  .package {
    max-width: 310px;
    padding-right: map-get($spacings, "medium");
    border-bottom: 1px solid map-get($colors, "bordering-gray");
    @include media-breakpoint-down(md) {
      display: none;
      padding-top: 0;
      padding-right: 0px;
      // top: 209px;
    }
    .package-activity {
      .package-name {
        display: none;
        @include media-breakpoint-down(md) {
          display: flex;
        }
        font-weight: 700;
      }
      .products {
        display: flex;
        gap: 2px;
        color: map-get($colors, "secondary");
        opacity: 0.6;
        .number {
          font-weight: 700;
        }
      }
    }
    .icon-delete {
      width: 20px;
      height: 20px;
      background-image: url("../../../assets/bin.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .edits {
    padding-top: map-get($spacings, "large");
    display: flex;
    flex-direction: column;
    gap: map-get($spacings, "default");
    .holder {
      display: flex;
      flex-direction: column;
      gap: map-get($spacings, "large");
    }
  }
  .image-text-holder {
    @include media-breakpoint-down(md) {
      display: none;
      padding-top: 0;
      padding-right: 0px;
      // top: 209px;
    }
    display: flex;
    gap: map-get($spacings, "small");
    .image {
      max-width: 100px;
      max-height: 100px;
    }
    .text-holder {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: map-get($spacings, "small");
    }
  }
}
</style>